import React, { useEffect, useState } from 'react';
import { List, Button, message } from 'antd';
import { getUserNotifications, markNotificationAsSeen } from '../../services/api';

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    async function fetchNotifications() {
      try {
        const data = await getUserNotifications();
        setNotifications(data.notifications);
      } catch (error) {
        message.error('Failed to load notifications.');
      }
    }
    fetchNotifications();
  }, []);

  const handleMarkAsSeen = async (id) => {
    try {
      await markNotificationAsSeen(id);
      setNotifications(notifications.filter((notif) => notif.id !== id));
      message.success('Notification marked as seen.');
    } catch (error) {
      message.error('Failed to update notification.');
    }
  };

  return (
    <List
      itemLayout="horizontal"
      dataSource={notifications}
      renderItem={(item) => (
        <List.Item
          actions={[
            <Button type="link" onClick={() => handleMarkAsSeen(item.id)}>
              Mark as seen
            </Button>,
          ]}
        >
          <List.Item.Meta title={item.title} description={item.content} />
        </List.Item>
      )}
    />
  );
};

export default Notifications;