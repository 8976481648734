import React, { useState, useContext } from 'react';
import { Layout, Menu } from 'antd';
import {
  UserOutlined,
  BookOutlined,
  NotificationOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Profile from './Profile';
import Bookings from './Bookings';
import Notifications from './Notifications';
import { AuthContext } from '../../contexts/AuthContext';

const { Header, Content, Footer, Sider } = Layout;

const Dashboard = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState('profile');
  const { setUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const handleMenuClick = (e) => {
    if (e.key === 'logout') {
      // Handle logout
      setUser(null);
      localStorage.removeItem('access_token');
      navigate('/login');
    } else {
      setSelectedMenu(e.key);
    }
  };

  const renderContent = () => {
    switch (selectedMenu) {
      case 'profile':
        return <Profile />;
      case 'bookings':
        return <Bookings />;
      case 'notifications':
        return <Notifications />;
      default:
        return <Profile />;
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
        <div className="logo" style={{ color: '#fff', padding: '16px', textAlign: 'center' }}>
          Bukica
        </div>
        <Menu
          theme="dark"
          defaultSelectedKeys={['profile']}
          mode="inline"
          onClick={handleMenuClick}
        >
          <Menu.Item key="profile" icon={<UserOutlined />}>
            Profile
          </Menu.Item>
          <Menu.Item key="bookings" icon={<BookOutlined />}>
            Bookings
          </Menu.Item>
          <Menu.Item key="notifications" icon={<NotificationOutlined />}>
            Notifications
          </Menu.Item>
          <Menu.Item key="logout" icon={<LogoutOutlined />}>
            Logout
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header style={{ background: '#fff', padding: '0 16px' }}>
          {/* Add any header content here */}
        </Header>
        <Content style={{ margin: '16px' }}>
          {renderContent()}
        </Content>
        <Footer style={{ textAlign: 'center' }}>Bukica ©2023</Footer>
      </Layout>
    </Layout>
  );
};

export default Dashboard;