import React, { createContext, useState, useEffect } from 'react';
import { getUserProfile } from '../services/api';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    async function fetchUser() {
      const token = localStorage.getItem('access_token');
      if (token) {
        try {
          const data = await getUserProfile();
          setUser(data);
        } catch {
          setUser(null);
          localStorage.removeItem('access_token');
        }
      }
    }
    fetchUser();
  }, []);

  return <AuthContext.Provider value={{ user, setUser }}>{children}</AuthContext.Provider>;
};