import React, { useEffect, useState } from 'react';
import { Table, Button, message, Popconfirm } from 'antd';
import { getUserBookings, cancelBooking } from '../../services/api';

const Bookings = () => {
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    async function fetchBookings() {
      try {
        const data = await getUserBookings();
        setBookings(data.bookings);
      } catch (error) {
        message.error('Failed to load bookings.');
      }
    }
    fetchBookings();
  }, []);

  const handleCancel = async (id) => {
    try {
      await cancelBooking(id);
      setBookings(bookings.filter((booking) => booking.id !== id));
      message.success('Booking canceled.');
    } catch (error) {
      message.error('Failed to cancel booking.');
    }
  };

  const columns = [
    {
      title: 'Service',
      dataIndex: 'service',
      key: 'service',
      render: (service) => service?.name || 'Unknown Service',
    },
    {
      title: 'Business',
      dataIndex: 'business',
      key: 'business',
      render: (business) => business?.config?.name || 'Unknown Business',
    },
    {
      title: 'Start Time',
      dataIndex: 'start_time',
      key: 'start_time',
      render: (time) => new Date(time).toLocaleString(),
    },
    {
      title: 'End Time',
      dataIndex: 'end_time',
      key: 'end_time',
      render: (time) => new Date(time).toLocaleString(),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) =>
        !record.cancelled_at ? (
          <Popconfirm
            title="Are you sure you want to cancel this booking?"
            onConfirm={() => handleCancel(record.id)}
          >
            <Button type="link">Cancel</Button>
          </Popconfirm>
        ) : (
          'Canceled'
        ),
    },
  ];

  return <Table dataSource={bookings} columns={columns} rowKey="id" />;
};

export default Bookings;