import axios from 'axios';

const API_BASE_URL = 'https://backend.bukica.krtech.dev'; // Update with your backend URL

axios.defaults.withCredentials = true;

// Add a request interceptor to include the access token
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Authentication APIs
export const loginUser = (email, password) =>
  axios
    .post(`${API_BASE_URL}/user/signin`, { email, password })
    .then((res) => res.data);

export const registerUser = (username, email, password) =>
  axios
    .post(`${API_BASE_URL}/user/signup`, { name: username, email, password })
    .then((res) => res.data);

// User Profile APIs
export const getUserProfile = () =>
  axios.get(`${API_BASE_URL}/user/`).then((res) => res.data);

export const updateUserProfile = (data) =>
  axios.patch(`${API_BASE_URL}/user/`, data).then((res) => res.data);

// Bookings APIs
export const getUserBookings = () =>
  axios.get(`${API_BASE_URL}/user/bookings`).then((res) => res.data);

export const cancelBooking = (id) =>
  axios.delete(`${API_BASE_URL}/user/bookings/${id}`).then((res) => res.data);

// Notifications APIs
export const getUserNotifications = () =>
  axios.get(`${API_BASE_URL}/user/notifications`).then((res) => res.data);

export const markNotificationAsSeen = (id) =>
  axios.put(`${API_BASE_URL}/user/notifications/${id}`).then((res) => res.data);