import React, { useEffect, useState } from 'react';
import { Form, Input, Button, message, Switch } from 'antd';
import { getUserProfile, updateUserProfile } from '../../services/api';

const Profile = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchProfile() {
      setLoading(true);
      try {
        const profile = await getUserProfile();
        form.setFieldsValue({
          username: profile.username,
          email: profile.email,
          phone: profile.settings?.phone || '',
          dark_mode: profile.settings?.dark_mode || false,
        });
      } catch (error) {
        message.error('Failed to load profile.');
      } finally {
        setLoading(false);
      }
    }
    fetchProfile();
  }, [form]);

  const onFinish = async (values) => {
    try {
      const data = {
        username: values.username,
        email: values.email,
        settings: {
          phone: values.phone,
          dark_mode: values.dark_mode,
        },
      };
      await updateUserProfile(data);
      message.success('Profile updated successfully!');
    } catch (error) {
      message.error('Failed to update profile.');
    }
  };

  return (
    <Form form={form} name="profile" onFinish={onFinish} layout="vertical">
      <Form.Item
        name="username"
        label="Name"
        rules={[
          { required: true, message: 'Please input your name!' },
          { min: 3, message: 'Name must be at least 3 characters.' },
        ]}
      >
        <Input disabled={loading} />
      </Form.Item>

      <Form.Item
        name="email"
        label="Email"
        rules={[
          { required: true, message: 'Please input your email!' },
          { type: 'email', message: 'Enter a valid email!' },
        ]}
      >
        <Input disabled={loading} />
      </Form.Item>

      <Form.Item name="phone" label="Phone Number">
        <Input disabled={loading} />
      </Form.Item>

      <Form.Item name="dark_mode" label="Dark Mode" valuePropName="checked">
        <Switch disabled={loading} />
      </Form.Item>

      {/* Add other settings fields as needed */}

      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={loading}>
          Save Changes
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Profile;