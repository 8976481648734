import React from 'react';
import { Form, Input, Button, message } from 'antd';
import { registerUser } from '../../services/api';
import { useNavigate } from 'react-router-dom';

const Register = () => {
  const navigate = useNavigate();

  const onFinish = async (values) => {
    try {
      await registerUser(values.username, values.email, values.password);
      message.success('Registration successful!');
      navigate('/login');
    } catch (error) {
      message.error('Registration failed. Please try again.');
    }
  };

  return (
    <div style={{ maxWidth: 400, margin: '50px auto' }}>
      <Form name="register" onFinish={onFinish}>
        <Form.Item
          name="username"
          rules={[
            { required: true, message: 'Please input your name!' },
            { min: 3, message: 'Name must be at least 3 characters.' },
          ]}
        >
          <Input placeholder="Name" />
        </Form.Item>

        <Form.Item
          name="email"
          rules={[
            { required: true, message: 'Please input your email!' },
            { type: 'email', message: 'Enter a valid email!' },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            { required: true, message: 'Please input your password!' },
            { min: 6, message: 'Password must be at least 6 characters.' },
          ]}
          hasFeedback
        >
          <Input.Password placeholder="Password" />
        </Form.Item>

        <Form.Item
          name="confirm"
          dependencies={['password']}
          hasFeedback
          rules={[
            { required: true, message: 'Please confirm your password!' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('The two passwords do not match!');
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirm Password" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Register
          </Button>
          Or <a href="/login">log in now!</a>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Register;